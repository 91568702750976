export enum HtmlElementId {
  welcomeScreen = "welcome-screen",
  headerWallets = "header-wallets",
  headerStatDecline = "header-stat-decline",
  balanceCardsPage = "balance-cards-page",
  limitCardsPage = "limit-cards-page",
  balanceCardsSidebarItem = "balance-cards-sidebar-item",
  faqSidebarItem = "faq-sidebar-item",
  supportSidebarItem = "support-sidebar-item",
  profileSidebarItem = "profile-sidebar-item",
  statisticSidebarItem = "statistic-sidebar-item",
  teamSidebarItem = "team-sidebar-item",
  cashFlowSidebarItem = "cash-flow-sidebar-item",
  transactionsSidebarItem = "transactions-sidebar-item",
  converterSidebarItem = "converter-sidebar-item",
  refillSidebarItem = "refill-sidebar-item",
  cardsFiltersContainerId = "cards-filters-container-id",
  cardsDownloadFilesContainerId = "cards-download-files-container-id",
  statisticDownloadFilesContainerId = "statistic-download-files-container-id",
  statisticGroupByField = "statistic-froup-by-field",
  statisticFiltersContainerId = "statistic-filters-container-id",
  cashFlowTable = "cash-flow-table",
  cashFlowFiltersContainerId = "cash-flow-filters-container-id",
  cashFlowFiltersButtonId = "cash-flow-filters-button-id",
  cashFlowDownloadFilesContainerId = "cash-flow-download-files-container-id",
  transactionsTable = "transactions-table",
  transactionsFiltersContainerId = "transactions-filters-container-id",
  transactionsFiltersButtonId = "transactions-filters-button-id",
  transactionsDownloadFilesContainerId = "transactions-download-files-container-id",
  issueCardButton = "issue-card-button",
  issueCard = "issue-card",
  issueCardBanksList = "issue-card-banks-list",
  issueCardBaseForm = "issue-card-base-form",
  issueCardTotalInfo = "issue-card-total-info",
  issueCardBankFeeInfo = "issue-card-bank-fee-info",
  headerBellButton = "header-bell-button",
  profileMaxSessionLimitField = "profile-max-session-limit-field",
  profileSecondFactorField = "profile-second-factor-field",
  profileBindPhoneNumberField = "profile-bind-phone-bumber-field",
  teamInviteUrlField = "team-invite-url-field",
  balanceCardsTable = "balance-cards-table",
  limitCardsTable = "limit-cards-table",
  paymentMethods = "payment-methods",
  usdtTransferConverter = "usdt-transfer-converter",
  usdtTransferWallet = "usdt-transfer-wallet",
  currencyConverterForm = "currency-converter-form",
}

export enum HtmlElementClassName {
  changeCardBalanceButton = "change-card-balance-button",
  cardSetLimitButton = "card-set-limit-button",
  cardSetLimitForm = "card-set-limit-form",
  cardsAutoRefillButton = "cards-auto-refill-button",
  cardActions = "card-actions",
  cardTransactionsActionButton = "card-transactions-action-button",
  cardStatisticActionButton = "card-statistic-action-button",
  cardPauseActionButton = "card-pause-action-button",
  cardCloseActionButton = "card-close-action-button",
  teamAutoRefillButton = "team-auto-refill-button",
  teamWalletTransferFunds = "team-wallet-transfer-funds",
  teamCardIssueLimit = "team-card-issue-limit",
  teamActions = "team-actions",
  teamMemberDeleteAction = "team-member-delete-action",
  selectBankIssueCard = "select-bank-issue-card-button",
}
