import { Component, Vue, Ref } from "vue-property-decorator";
import {
  getSymbolCurrency,
  formatMoney,
  numberToFixed,
  isPositiveNumber,
  isEmpty,
} from "@helpers";
import { WalletActions, WalletGetters } from "@store/modules/wallet/types";
import { walletModule } from "@store/namespaces";
import { Currency } from "@/types/currency";
import { VForm } from "@/types/vuetify";
import { ExchangeRates } from "@/types/exchangeRate";
import { HtmlElementId } from "@/types/element";

@Component
export default class CurrencyConverterMixin extends Vue {
  @Ref("form") private readonly formRef!: VForm;
  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];
  @walletModule.Action("fetchExchangeRates")
  private readonly fetchExchangeRatesAction!: WalletActions["fetchExchangeRates"];
  @walletModule.Action("exchangeFundsWallet")
  private readonly exchangeFundsWalletAction!: WalletActions["exchangeFundsWallet"];
  @walletModule.Getter("wallet")
  private readonly walletGetter!: WalletGetters["wallet"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  private isShowConfirmDialog = false;
  private loadingExchangeRates = false;
  private loadingExchangeFundsWallet = false;
  private fromCurrency: Currency = Currency.EUR;
  private toCurrency: Currency = Currency.USD;
  private amount = "";
  private formKey = Date.now();
  private exchangeRates: ExchangeRates = {
    lifetimeSec: 0,
    rates: {},
    fee: 0,
  };

  private get minAmount() {
    return 1;
  }

  private get htmlElementId() {
    return {
      currencyConverterForm: HtmlElementId.currencyConverterForm,
    };
  }

  private get fromCurrencyItems() {
    const toCurrency = this.toCurrency;

    return this.walletsCurrenciesGetter.filter((value) => value !== toCurrency);
  }

  private get toCurrencyItems() {
    const fromCurrency = this.fromCurrency;

    return this.walletsCurrenciesGetter.filter(
      (value) => value !== fromCurrency
    );
  }

  private get receiveAmount() {
    const amount = +this.amount;
    const exchangeRate =
      this.exchangeRates.rates[this.fromCurrency] &&
      this.exchangeRates.rates[this.fromCurrency][this.toCurrency];

    if (!exchangeRate || !amount || amount < 0) return "";

    const fee = this.exchangeRates.fee;

    const amountFee = Math.ceil(amount * fee * 100) / 100;

    return numberToFixed(
      Math.floor((amount - amountFee) * exchangeRate * 100) / 100,
      2
    );
  }

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      amount: (v: string) =>
        +v >= this.minAmount ||
        this.$vuetify.lang.t(
          "$vuetify.errors.min_amount",
          `${this.minAmount}${getSymbolCurrency(this.fromCurrency)}`
        ),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
    };
  }

  private get validForm() {
    return (
      [
        this.fieldRules.required(this.amount),
        this.fieldRules.positiveNumber(this.amount),
        this.fieldRules.amount(this.amount),
        !this.isInsufficientBalance,
      ].filter((v) => v !== true).length === 0
    );
  }

  private get isInsufficientBalance() {
    const currentBalance = this.walletGetter(this.fromCurrency).balance;

    return currentBalance <= 0 || currentBalance < +this.amount;
  }

  private formatMoney({
    value,
    currency,
  }: {
    value: number;
    currency: Currency;
  }) {
    return formatMoney({ value, currency });
  }

  private async fetchExchangeRates() {
    if (this.loadingExchangeRates) return;

    this.loadingExchangeRates = true;

    try {
      const data = await this.fetchExchangeRatesAction();

      if (data.lifetimeSec < 3) {
        window.setTimeout(() => {
          this.loadingExchangeRates = false;
          this.fetchExchangeRates();
        }, data.lifetimeSec * 1e3);
      } else {
        this.loadingExchangeRates = false;
        this.exchangeRates = data;
      }
    } catch {
      this.loadingExchangeRates = false;
    }
  }

  private onSubmitForm() {
    if (!this.formRef.validate()) return;

    this.isShowConfirmDialog = true;
  }

  private async exchangeFundsWallet() {
    if (this.loadingExchangeFundsWallet) return;

    this.loadingExchangeFundsWallet = true;

    try {
      await this.exchangeFundsWalletAction({
        fromCurrency: this.fromCurrency,
        toCurrency: this.toCurrency,
        amount: +this.amount,
      });
      await this.fetchWalletsAction();

      this.$notify({
        type: "success",
        title: this.$vuetify.lang.t(
          "$vuetify.dashboard.currency_converter.successful_conversion"
        ),
      });
      this.loadingExchangeFundsWallet = false;
      this.amount = "";
      this.isShowConfirmDialog = false;
      this.formKey++;
    } catch {
      this.loadingExchangeFundsWallet = false;
    }
  }

  private reverseSenderAndRecipient() {
    const fromCurrency = this.fromCurrency;
    const toCurrency = this.toCurrency;

    this.fromCurrency = toCurrency;
    this.toCurrency = fromCurrency;

    this.formRef.validate();
  }

  private getCurrencyIcon(currency: Currency) {
    return require(`@/assets/img/currency/flags/${currency}.svg`);
  }

  private getSymbolCurrency(currency: Currency) {
    return getSymbolCurrency(currency);
  }

  private mounted() {
    let lifetimeSecIntervalId = 0;

    this.$watch(
      () => {
        return this.exchangeRates.lifetimeSec === 0;
      },
      (refreshed) => {
        if (refreshed) {
          this.fetchExchangeRates();
        } else {
          window.clearInterval(lifetimeSecIntervalId);

          lifetimeSecIntervalId = window.setInterval(() => {
            if (this.exchangeRates.lifetimeSec > 0) {
              this.exchangeRates.lifetimeSec--;
            } else {
              window.clearInterval(lifetimeSecIntervalId);
            }
          }, 1e3);
        }
      },
      { immediate: true }
    );

    this.$once("hook:beforeDestroy", () => {
      window.clearInterval(lifetimeSecIntervalId);
    });
  }
}
